// Here you can add other styles

.image-profile {
  width: 100%;
  height: 50px;
  display: block;
  border-radius: 100%;
  overflow: hidden;
}

.image-size {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.ellipsis {
  display: block;
  max-width: 6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  white-space: nowrap;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  max-width: 20rem;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: normal;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
}
